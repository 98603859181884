'use client'

import { CategoryTitle, FlexContainer, HorizontalCarousel, MediaCard } from '@cheddartv/storybook-news'

import React, { Fragment } from 'react'

import { trackGTMEvent } from '../../config/analytics'
import useBelowBreakpoint from '../../hooks/useBelowBreakpoint'
import { Breakpoints } from '../../hooks/useBelowBreakpoint'
import { StoryPreview } from '../../types'
import { getDuration } from '../../utils/duration'
import AdUnit from '../Ads/AdUnit'

const defaultAdUnitCategories = []

type CollectionsProps = {
  adUnitCategories?: string[]
  category: string
  stories: StoryPreview[]
  shouldShowAd?: boolean
  shouldShowArrows?: boolean
  fullWidthBreakpoint?: Breakpoints
}

const StoriesSection = ({
  adUnitCategories = defaultAdUnitCategories,
  category,
  stories,
  shouldShowAd,
  shouldShowArrows = true,
  fullWidthBreakpoint = 'sm'
}: CollectionsProps) => {
  const isFullWidthBreakpointReached = useBelowBreakpoint(fullWidthBreakpoint)

  return (
    <FlexContainer direction='column' gap='1.5rem'>
      <FlexContainer padding={{ default: '0 1rem', [fullWidthBreakpoint]: 0 }}>
        <CategoryTitle uppercased withMarker tag='h5' fontWeight='semibold'>
          {category}
        </CategoryTitle>
      </FlexContainer>
      <HorizontalCarousel showArrows={shouldShowArrows}>
        {stories.map(({ id, title, thumbnail, durationTime, href }, index) => (
          <Fragment key={id}>
            {!isFullWidthBreakpointReached && shouldShowAd && index === 0 && (
              <AdUnit
                adUnitTemplate='square'
                contentfulCategories={adUnitCategories}
                id={`ad-story-collection-${category}`}
                type='storiesCollection'
              />
            )}
            <MediaCard
              inverseTextColor
              durationTime={getDuration(durationTime)}
              heading={title}
              onClick={() =>
                trackGTMEvent.gaMediaCardClick({
                  cardTitle: title,
                  sectionTitle: category,
                  cardNumber: index + 1
                })
              }
              thumbnail={{
                alt: title,
                src: thumbnail
              }}
              href={href}
            />
          </Fragment>
        ))}
      </HorizontalCarousel>
    </FlexContainer>
  )
}

export default StoriesSection
