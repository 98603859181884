import { Size, TextComponent } from '@cheddartv/storybook-news'

import style from '../styles/components/notfound'

export default () => (
  <div className='wrapper'>
    <h1>Page not found</h1>
    <p>
      <TextComponent tag='p' size={Size.Medium}>
        We can’t find the page you’re looking for. Try going back to the previous page or to{' '}
        <a href='http://news12.com'>news12.com</a>
      </TextComponent>
    </p>
    <style jsx>{style}</style>
  </div>
)
