'use client'

import React from 'react'

import bannerStyles from '../../styles/components/homePage/banner_styles'

import Image from 'next/image'

export type BannerProps = {
  banner: string
}

const Banner = ({ banner }: BannerProps) => {
  return (
    <>
      <section className='banner-section'>
        <Image fill src={banner} alt='News 12 New York banner' className='wide-banner' />
      </section>
      <style jsx>{bannerStyles}</style>
    </>
  )
}

export default Banner
