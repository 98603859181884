import React from 'react'

import HomePage, { HomePageProps } from '../components/HomePage/HomePage'
import NYLandingPage from '../components/NYLandingPage/NYLanding'
import NotFound from '../components/NotFound'
import { logServerSidePropsError } from '../components/utilities/log-helper'
import { Region } from '../config/regions'
import { STATUS_CODE } from '../data/enums/statusCodes'
import { NewslettersList } from '../data/query/fetchNewslettersList'
import fetchHomepageData from '../data/query/homepage/fetchHomepageData'
import fetchNYLandingData, { HomepageNYLandingData } from '../data/query/homepage/fetchNYLandingData'
import { SurrogateKeys } from '../types'
import { RouteRedMetrics } from '../utils/metrics'

export type CrunchAppProps = {
  NYLandingPageData?: HomepageNYLandingData
  homepageData: HomePageProps
  newslettersList: NewslettersList
  regionName?: string
  error?: string
}

export default ({ NYLandingPageData, homepageData, newslettersList, regionName, error }: CrunchAppProps) => {
  if (error) {
    return <NotFound />
  }

  if (NYLandingPageData) {
    return <NYLandingPage {...NYLandingPageData} />
  }

  return <HomePage {...homepageData} newslettersList={newslettersList} regionName={regionName!} />
}

export async function getServerSideProps(context) {
  const regionName = context?.req?.headers?.host?.split('.')?.[0]
  const redMetrics = new RouteRedMetrics({
    method: 'GET',
    route: 'homepage'
  }).start()

  const surrogateKeyHeader = context?.res?.getHeader('Surrogate-Key')?.split(' ')
  try {
    if (regionName === Region.NEW_YORK) {
      const NYLandingPageData = await fetchNYLandingData()

      if (!NYLandingPageData) {
        redMetrics.end({
          status: STATUS_CODE.notFound,
          error: 'NY Landing Page data not found'
        })

        return { notFound: true }
      }

      redMetrics.end({
        status: STATUS_CODE.success
      })

      const surrogateKeys = [...surrogateKeyHeader, ...(NYLandingPageData?.entryIDs as SurrogateKeys)]
        .filter(Boolean)
        .join(' ')
      context?.res?.setHeader('Surrogate-Key', surrogateKeys)
      delete NYLandingPageData.entryIDs

      return {
        props: {
          NYLandingPageData
        }
      }
    }

    const homepageData = await fetchHomepageData(regionName)

    if (!homepageData) {
      redMetrics.end({
        status: STATUS_CODE.notFound,
        error: 'Home Page data not found'
      })

      return { notFound: true }
    }

    redMetrics.end({
      status: STATUS_CODE.success
    })

    const surrogateKeys = [...surrogateKeyHeader, ...(homepageData.entryIDs as SurrogateKeys)].filter(Boolean).join(' ')
    context?.res?.setHeader('Surrogate-Key', surrogateKeys)

    delete homepageData.entryIDs

    return {
      props: {
        homepageData,
        regionName
      }
    }
  } catch (error) {
    logServerSidePropsError(error)

    redMetrics.end({
      status: STATUS_CODE.internalServerError,
      error: 'Failed to load Homepage data'
    })

    return { props: { error: 'Failed to load Homepage data' } }
  }
}
